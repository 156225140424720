import styled from 'styled-components';
import { Link } from 'gatsby';

export const PiercerName = styled('h2')`
  font-size: 18px;
  text-align: center;
  display: grid;
  grid-row-gap: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  text-shadow: 0 0 4px black;
`;

export const PiercerRole = styled('span')`
  font-style: italic;
  font-size: 14px;
`;

export const PiercerPhoto = styled('img')`
  justify-self: center;
  width: 100%;
  filter: grayscale(0.8);
  transition: filter 0.1s linear;
`;

export const PiercerLink = styled(Link)`
  display: grid;
  color: #222;
  position: relative;
  overflow: hidden;
  max-height: 250px;

  &:hover {
    ${PiercerName} {
      text-decoration: underline;
    }

    ${PiercerPhoto} {
      filter: grayscale(0);
    }
  }
`;
