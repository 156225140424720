import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { PiercerName, PiercerRole, PiercerLink, PiercerPhoto } from './styles';
import { useTranslation } from 'react-i18next';

const PiercerCard = (props) => {
  const { piercer } = props;
  const piercerNickname = piercer.nickname ? `"${piercer.nickname}"` : '';
  const piercerName =
    piercer.firstName && piercer.lastName
      ? `${piercer.firstName} ${piercerNickname} ${piercer.lastName}`
      : piercer.nickname;
  const { t } = useTranslation('translation');

  const ROLE_MAPPING = {
    1: t('president'),
    10: t('vicePresident'),
    20: t('boardMember'),
    30: t('treasurer'),
    40: t('member'),
  };

  const noPhoto = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "no-photo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <PiercerLink to={`/nasi-piercerzy/${piercer.slug}/`}>
      <PiercerPhoto
        src={piercer.thumbnail || noPhoto.placeholderImage.childImageSharp.fluid.src}
        width="100%"
      />
      <PiercerName>
        {piercerName}
        <PiercerRole>{ROLE_MAPPING[piercer.role]}</PiercerRole>
      </PiercerName>
    </PiercerLink>
  );
};

export default PiercerCard;
