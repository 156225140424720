import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { PageTitle, PiercersGrid } from '../components/styles/global';
import PiercerCard from '../components/PiercerCard';

export const QUERY = graphql`
  {
    graphAPI {
      allPiercers {
        id
        slug
        role
        thumbnail
        firstName
        lastName
        nickname
        photo
        socialLinks
        content {
          favouritePiercing
          inFreeTime
          otherModifications
          otherWorkPlace
          preferredContact
          startWorkExperience
          trainingAndCertifications
          typesOfPiercings
          workMostEmphasis
          workMostLike
          workPlace
        }
      }
    }
  }
`;

const OurPiercers = ({
  data: {
    graphAPI: { allPiercers },
  },
}) => {
  const { t } = useTranslation('translation');

  return (
    <>
      <SEO title={t('ourPiercers')} />
      <PageTitle>{t('ourPiercers')}</PageTitle>
      <PiercersGrid>
        {allPiercers.map((piercer) => (
          <PiercerCard key={piercer.id} piercer={piercer} />
        ))}
      </PiercersGrid>
    </>
  );
};
export default OurPiercers;
